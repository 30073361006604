@import '../../constants/colors.scss';

.button {
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;
  height: fit-content;

  transition: 0.2s ease background-color;
  padding: 10px 12px;
  // margin: auto;
  border: none;

  :disabled {
    background-color: #fff;
  }

  &--success {
    background-color: $green;

    &:hover {
      background-color: $dark-green;
    }
  }

  &--warn {
    background-color: $yellow;

    &:hover {
      background-color: $dark-yellow;
    }
  }

  &--info {
    background-color: $blue;

    &:hover {
      background-color: $dark-blue;
    }
  }

  &--danger {
    background-color: $red;

    &:hover {
      background-color: $dark-red;
    }
  }

  &--black {
    background-color: $card-dark;

    &:hover {
      background-color: $extra-dark;
    }
  }

  .body-text-1 {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: #fff;
  }
}
